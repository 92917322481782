import * as React from 'react'

function WhatsAppIcon(props) {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{'WhatsApp'}</title>
      <path
        d="M14.5 0C6.50161 0 0 6.50161 0 14.5C0 18.1952 1.37984 21.7032 3.90565 24.3927L0.678226 27.4331V29H14.5C22.4984 29 29 22.4984 29 14.5C29 6.50161 22.4984 0 14.5 0ZM14.5 27.129H3.71855L6.59516 24.4395L5.58952 23.4339C3.18064 21.0484 1.87097 17.8677 1.87097 14.5C1.87097 7.53065 7.53065 1.87097 14.5 1.87097C21.4694 1.87097 27.129 7.53065 27.129 14.5C27.129 21.4694 21.4694 27.129 14.5 27.129Z"
        fill="black"
      />
      <path
        d="M14.3361 18.3822C12.8394 17.4233 11.5765 16.1137 10.6644 14.5701L12.2313 13.0032V8.81689H7.17969C7.17969 16.1371 13.0966 22.054 20.4168 22.054V17.0258H15.6926L14.3361 18.3822Z"
        fill="black"
      />
    </svg>
  )
}

export default WhatsAppIcon
