import { navigate } from 'gatsby-link'

const encode = data => {
  return Object.keys(data).map(key => ({
    name: key,
    value: data[key],
  }))
}

function redirectAfterSubmission() {
  navigate('')
}

function submit(formData) {
  return fetch(
    process.env.GATSBY_HUBSPOT_FORM_URL,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fields: encode({ ...formData }),
        legalConsentOptions: {
          consent: {
            consentToProcess: true,
            text: 'I agree to allow Abelcare to store and process my personal data.',
            communications: [
              {
                value: true,
                subscriptionTypeId: 999,
                text: 'I agree to receive a response from Abelcare.',
              },
            ],
          },
        },
      }),
    }
  ).then(() => {
    setTimeout(redirectAfterSubmission, 4000)
  })
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default { submit }
