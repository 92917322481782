import React from 'react'

function FormField(props) {
  return (
    <>
      {props.type === 'textarea' && (
        <textarea
          className={props.className}
          value={props.value}
          name={props.name}
          placeholder={props.placeholder}
          onChange={e => props.onChange(e.target.value)}
        />
      )}

      {props.type !== 'textarea' && (
        <input
          className={props.className}
          type={props.type}
          value={props.value}
          name={props.name}
          placeholder={props.placeholder}
          onChange={e => props.onChange(e.target.value)}
        />
      )}

      {props.error && <span className="text-red-600 font-light">{props.error.message}</span>}
    </>
  )
}

export default FormField
