import React, { useState } from 'react'
import FormField from './FormField'

export function ContactForm(props) {
  // State for input values
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')

  // Whether to show errors
  // We set to true if they submit and there are errors.
  // We only show errors after they submit because
  // it's annoying to see errors while typing.
  const [showErrors, setShowErrors] = useState(false)

  // Error array we'll populate
  let errors = []

  // Function for fetching error for a field
  const getError = field => {
    return errors.find(e => e.field === field)
  }

  // Function to see if field is empty
  const isEmpty = val => val.trim() === ''

  // Add error if email empty
  if (isEmpty(email)) {
    errors.push({
      field: 'email',
      message: props.formInfo.emailTitle + ' ' + props.formInfo.isRequiredMessage,
    })
  }

  // Add error if message empty
  if (isEmpty(message)) {
    errors.push({
      field: 'message',
      message: props.formInfo.messageTitle + ' ' + props.formInfo.isRequiredMessage,
    })
  }

  // Add error if name shown and empty
  if (isEmpty(firstname)) {
    errors.push({
      field: 'firstname',
      message: props.formInfo.firstNameTitle + ' ' + props.formInfo.isRequiredMessage,
    })
  }

  // Add error if name shown and empty
  if (isEmpty(lastname)) {
    errors.push({
      field: 'lastname',
      message: props.formInfo.lastNameTitle + ' ' + props.formInfo.isRequiredMessage,
    })
  }

  // Handle form submission
  const handleSubmit = () => {
    // If field errors then show them
    if (errors.length) {
      setShowErrors(true)
    } else {
      // Otherwise call onSubmit with form data
      if (props.onSubmit) {
        props.onSubmit({
          firstname,
          lastname,
          email,
          message,
        })
      }
    }
  }

  return (
    <div className="order-last md:order-first mt-12 sm:mt-0">
      <h2 className="font-serif text-3xl md:text-4xl pb-3">
        {props.formInfo.contactFormTitle}
      </h2>

      <form
        onSubmit={e => {
          e.preventDefault()
          handleSubmit()
        }}
        name="contact"
        method="post"
        className="px-6 md:px-12 py-8 rounded-md space-y-3 bg-beige-dark"
      >
        <FormField
          value={firstname}
          name="firstname"
          type="text"
          placeholder={props.formInfo.firstNameTitle}
          error={showErrors && getError('firstname')}
          onChange={value => setFirstname(value)}
          className="shadow-sm focus:ring-abel-green focus:border-abel-green block w-full sm:text-sm border-gray-300 px-4 py-2 rounded-full outline-none"
        />

        <FormField
          value={lastname}
          name="lastname"
          type="text"
          placeholder={props.formInfo.lastNameTitle}
          error={showErrors && getError('lastname')}
          onChange={value => setLastname(value)}
          className="shadow-sm focus:ring-abel-green focus:border-abel-green block w-full sm:text-sm border-gray-300 px-4 py-2 rounded-full outline-none"
        />

        <FormField
          value={email}
          name="email"
          type="email"
          placeholder={props.formInfo.emailTitle}
          error={showErrors && getError('email')}
          onChange={value => setEmail(value)}
          className="shadow-sm focus:ring-abel-green focus:border-abel-green block w-full sm:text-sm border-gray-300 px-4 py-2 rounded-full outline-none"
        />
        <FormField
          value={message}
          name="message"
          type="textarea"
          placeholder={props.formInfo.messageTitle}
          error={showErrors && getError('message')}
          onChange={value => setMessage(value)}
          className="shadow-sm focus:ring-abel-green focus:border-abel-green block w-full sm:text-sm border-gray-300 px-4 py-2 rounded-full outline-none"
        />
        {props.status && props.status.type === 'pending' ? (
          <button
            type="submit"
            disabled={true}
            className="rounded-full bg-abel-green w-full inline-flex align-middle justify-center text-center leading-6 text-white py-3 text-lg sm:mt-8 mt-4 hover:bg-gray-500 font-light"
          >
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span>{props.formInfo.sendingButton}</span>
          </button>
        ) : (
          <button
            type="submit"
            disabled={props.status && props.status.type === 'success'}
            className="rounded-full bg-abel-green w-full inline-flex align-middle justify-center text-center leading-6 text-white py-3 text-lg sm:mt-8 mt-4 hover:bg-gray-500 font-light"
          >
            <span>{props.formInfo.sendButton}</span>
          </button>
        )}
      </form>
    </div>
  )
}
