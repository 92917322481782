import React from 'react'
import { Fragment, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Container } from '../components/container'
import { SectionTitle } from '../components/section-title'
import { ContactForm } from '../components/contact/contact-form'
import PhoneIcon from '../icons/contact/phone'
import WhatsAppIcon from '../icons/contact/whatsapp'
import contact from '../utils/contact'

const ContactPage = ({ data: { contactPage, links } }) => {
  const [status, setStatus] = useState({ type: '', message: '' })

  const formInfo = {
    contactFormTitle: contactPage.contactFormTitle,
    sendButton: contactPage.sendButton,
    sendingButton: contactPage.sendingButton,
    emailTitle: contactPage.emailTitle,
    firstNameTitle: contactPage.firstNameTitle,
    lastNameTitle: contactPage.lastNameTitle,
    messageTitle: contactPage.messageTitle,
    isRequiredMessage: contactPage.isRequiredMessage,
  }

  const onSubmit = ({ firstname, lastname, email, message }) => {
    setStatus({ type: 'pending', message: '' })

    contact.submit({ firstname, lastname, email, message }).then(() => {
      setStatus({
        type: 'success',
        message: contactPage.successMessage,
      })
      setShow(true)
    })
  }

  const updatedLinks = links.nodes.reduce(
    (newObj, item) => ((newObj[item.locale] = item.slug), newObj),
    {}
  )

  const [show, setShow] = useState(false)

  return (
    <Layout hrefLangLinks={updatedLinks} seoMetaTags={contactPage.seoMetaTags}>
      <Container color="bg-beige">
        <SectionTitle title={contactPage.title} main={true} centered={true} />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <ContactForm
            formInfo={formInfo}
            onSubmit={onSubmit}
            status={status}
          />

          <div className="order-first md:order-last">
            <h2 className="text-black font-serif text-3xl md:text-4xl pb-3">
              {contactPage.ourInfoTitle}
            </h2>
            <div className="px-6 md:px-12 py-8 rounded-md bg-beige-dark">
              <a href={contactPage.ourInfoPhoneUrl}>
                <div className=" bg-white rounded-xl px-3 md:px-5 py-5">
                  <div className="flex">
                    <PhoneIcon className="flex-shrink-0 w-5" />
                    <p className="text-black flex-none font-lg ml-3 flex-shrink-0">
                      {contactPage.ourInfoPhoneTitle}
                    </p>
                  </div>
                  <span className="ml-8">{contactPage.ourInfoPhoneNumber}</span>
                </div>
              </a>
              <a href={contactPage.ourInfoWhatsappUrl}>
                <div className="bg-white rounded-xl px-3 md:px-5 py-5 mt-6">
                  <div className="flex">
                    <WhatsAppIcon className="flex-shrink-0 w-5" />
                    <p className="text-black flex-none font-lg ml-3 flex-shrink-0">
                      {contactPage.ourInfoWhatsappTitle}
                    </p>
                  </div>
                  <span className="ml-8">{contactPage.ourInfoPhoneNumber}</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </Container>

      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">
                      {contactPage.successTitle}
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      {status.message}
                    </p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ($locale: String!) {
    contactPage: datoCmsContactPage(locale: { eq: $locale }) {
      title
      contactFormTitle
      sendButton
      sendingButton
      emailTitle
      firstNameTitle
      lastNameTitle
      messageTitle
      isRequiredMessage
      successTitle
      successMessage
      ourInfoTitle
      ourInfoPhoneTitle
      ourInfoPhoneNumber
      ourInfoPhoneUrl
      ourInfoWhatsappTitle
      ourInfoWhatsappUrl
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    links: allDatoCmsContactPage {
      nodes {
        slug
        locale
      }
    }
  }
`
